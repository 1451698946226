// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';

export enum BreakPoints {
	FULL,
	NO_LAST_ACTION,
	NO_SUBTITLE,
}

const getGridTemplateColumns = (breakPoint: BreakPoints) => {
	switch (breakPoint) {
		case BreakPoints.FULL:
			return '32px minmax(300px, 2fr) 1fr 1fr 128px';
		case BreakPoints.NO_LAST_ACTION:
			return '32px minmax(300px, 2fr) 1fr 0fr 128px';
		case BreakPoints.NO_SUBTITLE:
			return '32px minmax(300px, 2fr) 0fr 0fr 128px';
	}
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const ListCard = styled.div<{ breakPoint: BreakPoints }>`
	min-width: 0;
	padding: ${token('space.100', '8px')} ${token('space.200', '16px')};
	display: grid;
	grid-template-columns: ${(props) => getGridTemplateColumns(props.breakPoint)};
	align-items: center;
`;
