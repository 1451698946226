import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';

import { token } from '@atlaskit/tokens';
import { WidthObserver } from '@atlaskit/width-detector';
import { N40 } from '@atlaskit/theme/colors';

import { PageCard } from './PageCard';
import type { PageCard as PageCardType, PageCardAppearance } from './card-types';
import { PageCardsContext } from './PageCardsContext';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageCardsGridContainer = styled.div({
	display: 'grid',
	minWidth: '295px',
	maxWidth: '1320px',
	gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
	gap: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageCardsListContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	minWidth: '600px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div + div': {
		marginTop: token('space.100', '8px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageCardsCompactListContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	minWidth: '600px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > div:first-of-type > div': {
		borderRadius: '4px 4px 0 0',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > div:nth-last-of-type(2) > div': {
		borderBottom: `1px solid ${token('color.border', N40)}`,
		borderRadius: '0 0 4px 4px',
	},
});

export type AnalyticsData = {
	source?: string;
	attributes?: { [key: string]: any };
};

type PageCardsProps = {
	appearance: PageCardAppearance;
	nodes: PageCardType[];
	analyticsData?: AnalyticsData;
};

export const PageCards: FC<PageCardsProps> = ({ appearance, nodes, analyticsData }) => {
	const PageCardsContainer =
		appearance === 'grid'
			? PageCardsGridContainer
			: appearance === 'list'
				? PageCardsListContainer
				: PageCardsCompactListContainer;
	const [width, setWidth] = useState(0);
	const onResize = debounce(setWidth, 200, { leading: false });

	const pageCardsContextValue = useMemo(() => ({ width }), [width]);

	return (
		<PageCardsContext.Provider value={pageCardsContextValue}>
			<PageCardsContainer>
				{nodes.map((node, i) => (
					<PageCard
						appearance={appearance}
						data={node}
						key={node.key || node.id}
						ranking={i}
						analyticsData={analyticsData}
					/>
				))}
				<WidthObserver setWidth={onResize} offscreen />
			</PageCardsContainer>
		</PageCardsContext.Provider>
	);
};
