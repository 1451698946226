import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import {
	ContentIcon,
	QuickActions,
	Subtitle,
	LastAction,
	StatusLozenge,
	Title,
	PageLink,
	PageCardContainer,
} from '../Items';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ListCard = styled.div({
	minWidth: 0,
	display: 'flex',
	alignItems: 'center',
	padding: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginBottom: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentIconContainer = styled.div({
	alignSelf: 'flex-start',
	margin: `${token('space.050', '4px')} ${token('space.200', '16px')} 0 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MainContentContainer = styled.div({
	alignSelf: 'flex-start',
	flex: '1 1 0px',
	minWidth: '300px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleText = styled.div({
	marginRight: token('space.100', '8px'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LastActionContainer = styled.div({
	flex: '0 1 300px',
	minWidth: '100px',
});

export const ListView: FC = () => {
	// ⚠️ WARNING: Do you need to add conditional logic? Please refer to README#Views ⚠️
	return (
		<PageCardContainer>
			<PageLink />
			<ListCard data-testid="page-card-list-view">
				<ContentIconContainer>
					<ContentIcon />
				</ContentIconContainer>
				<MainContentContainer>
					<TitleContainer>
						<TitleText>
							<Title singleLine />
						</TitleText>
						<StatusLozenge />
					</TitleContainer>
					<Subtitle />
				</MainContentContainer>
				<LastActionContainer>
					<LastAction />
				</LastActionContainer>
				<QuickActions hidden />
			</ListCard>
		</PageCardContainer>
	);
};
