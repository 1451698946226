import type { FC } from 'react';
import React from 'react';

import { Grid24Icon } from './images/Grid24Icon';

type Size = 'small' | 'medium';

type GridIconProps = {
	label: string;
	size?: Size;
	primaryColor?: string;
	testId?: string;
};

export const GridIcon: FC<GridIconProps> = ({ label, size, primaryColor, testId }) => {
	return React.createElement(Grid24Icon, {
		size,
		label,
		testId,
		primaryColor,
	});
};
