import React from 'react';

import Icon from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';

const list24Svg = () => (
	<svg width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect
			width="24"
			height="24"
			transform="translate(0.5)"
			fillOpacity="0"
			fill={token('elevation.surface', '#FFFFFF')}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.5 4C5.39543 4 4.5 4.89543 4.5 6V9C4.5 10.1046 5.39543 11 6.5 11H18.5C19.6046 11 20.5 10.1046 20.5 9V6C20.5 4.89543 19.6046 4 18.5 4H6.5ZM18.5 6H6.5V9H18.5V6Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.5 13C5.39543 13 4.5 13.8954 4.5 15V18C4.5 19.1046 5.39543 20 6.5 20H18.5C19.6046 20 20.5 19.1046 20.5 18V15C20.5 13.8954 19.6046 13 18.5 13H6.5ZM18.5 15H6.5V18H18.5V15Z"
			fill="currentColor"
		/>
	</svg>
);

export const List24Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon', '#44546F');
	const label = props.label || '';
	return <Icon glyph={list24Svg} {...props} primaryColor={primaryColor} label={label} />;
};
