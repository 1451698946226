import type { FC } from 'react';
import React, { useState } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { defineMessages, useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';
import Tooltip from '@atlaskit/tooltip/Tooltip';

import { GridIcon, ListIcon, CompactIcon } from '@confluence/icons/entry-points/EntityViewIcons';

import { PageCardAppearanceOption } from './PageCardAppearanceOption';
import type { PageCardAppearance } from './card-types';

const toggleContainerStyles = css({
	display: 'inline-flex',
	flexDirection: 'row',
	alignItems: 'center',
	gap: token('space.050'),
	listStyleType: 'none',
	margin: 0,
	padding: 0,
	justifyContent: 'center',
});

const i18n = defineMessages({
	cardView: {
		id: 'page-card.toggle.card-view',
		defaultMessage: 'Cards',
		description: 'Text for button that switches page card view to grid (card) view',
	},
	listView: {
		id: 'page-card.toggle.list-view',
		defaultMessage: 'List',
		description: 'Text for button that switches page card view to list view',
	},
	compactListView: {
		id: 'page-card.toggle.compact-list-view',
		defaultMessage: 'Compact list',
		description: 'Text for button that switches page card view to compact list view',
	},
	radioGroupLabel: {
		id: 'page-card.toggle.radio-group-label',
		defaultMessage: 'Appearance',
		description: 'Label for page card view radio group',
	},
});

type PageCardAppearanceToggle = {
	appearance: PageCardAppearance;
	setAppearance: (PageCardAppearance) => void;
	disabled?: boolean;
	order?: PageCardAppearance[];
	source?: string;
};
export const PageCardAppearanceToggle: FC<PageCardAppearanceToggle> = ({
	appearance,
	setAppearance,
	disabled,
	order = ['grid', 'list', 'compact-list'],
	source,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const intl = useIntl();

	const onClick = (appearance: PageCardAppearance) => {
		if (disabled) {
			return;
		}

		setAppearance(appearance);

		const actionSubjectId = appearance === 'grid' ? 'cards' : 'list';
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source,
				action: 'clicked',
				actionSubject: 'tab',
				actionSubjectId,
			},
		}).fire();
	};

	const toggles: {
		[key in PageCardAppearance]: {
			id: PageCardAppearance;
			i18n: MessageDescriptor;
			icon: React.ElementType;
		} | null;
	} = {
		grid: {
			id: 'grid',
			i18n: i18n.cardView,
			icon: GridIcon,
		},
		list: {
			id: 'list',
			i18n: i18n.listView,
			icon: ListIcon,
		},
		'compact-list': {
			id: 'compact-list',
			i18n: i18n.compactListView,
			icon: CompactIcon,
		},
		'popup-view': null,
		'end-of-page': null,
	};

	return (
		<ul
			css={toggleContainerStyles}
			// eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
			role="radiogroup"
			aria-label={intl.formatMessage(i18n.radioGroupLabel)}
		>
			{order.map((t) => {
				const toggle = toggles[t];
				const ToggleIcon = toggle?.icon;
				return (
					toggle &&
					ToggleIcon && (
						<Tooltip
							key={toggle.id}
							content={intl.formatMessage(toggle.i18n)}
							hideTooltipOnClick
							position="top"
						>
							<li key={toggle.id}>
								<PageCardAppearanceOption
									onClick={() => onClick(toggle.id)}
									isChecked={appearance === toggle.id}
									ToggleIcon={ToggleIcon}
									disabled={disabled}
									label={intl.formatMessage(toggle.i18n)}
								/>
							</li>
						</Tooltip>
					)
				);
			})}
		</ul>
	);
};

export const useAppearanceToggle = (defaultAppearance: PageCardAppearance = 'grid') => {
	const [appearance, setAppearance] = useState(defaultAppearance);
	return { appearance, setAppearance };
};
