import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import {
	ContentIcon,
	QuickActions,
	Subtitle,
	LastAction,
	StatusLozenge,
	Title,
	SocialProof,
	Excerpt,
	PageLink,
	PageCardContainer,
} from '../Items';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GridCard = styled.div({
	display: 'flex',
	flexDirection: 'column',
	height: 'calc(100% - 28px)',
	width: 'calc(100% - 32px)',
	maxHeight: '260px',
	padding: `${token('space.150', '12px')} ${token('space.200', '16px')} ${token(
		'space.200',
		'16px',
	)} ${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleContainer = styled.div({
	margin: `${token('space.200', '16px')} 0 ${token('space.050', '4px')} 0`,
	fontSize: '20px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	overflowWrap: 'break-word',
	display: '-webkit-box',
	WebkitLineClamp: '2',
	WebkitBoxOrient: 'vertical',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterContainer = styled.div({
	flex: '1 0 auto',
	display: 'flex',
	alignItems: 'flex-end',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Footer = styled.div({
	width: '100%',
	marginTop: token('space.200', '16px'),
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

export const GridView: FC = () => {
	// ⚠️ WARNING: Do you need to add conditional logic? Please refer to README#Views ⚠️
	return (
		<PageCardContainer>
			<PageLink />
			<GridCard data-testid="page-card-grid-view">
				<Header>
					<ContentIcon />
					<QuickActions hidden />
				</Header>
				<TitleContainer>
					<Title />
				</TitleContainer>
				<Subtitle />
				<StatusLozenge isInColumn />
				<Excerpt />
				<FooterContainer>
					<Footer>
						<LastAction />
						<SocialProof />
					</Footer>
				</FooterContainer>
			</GridCard>
		</PageCardContainer>
	);
};
