import type { FunctionComponent } from 'react';
import React, { useCallback, useState } from 'react';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N70, N500, B200 } from '@atlaskit/theme/colors';

const inputStyle = css({
	position: 'absolute',
	opacity: 0,
	pointerEvents: 'none',
	height: token('space.300', '24px'),
	width: token('space.300', '24px'),
});

const baseLabelStyle = css({
	display: 'flex',
	cursor: 'pointer',
	borderRadius: token('border.radius.100', '4px'),
	placeContent: 'center',
	alignItems: 'center',
});

const hoverAndFocusStyle = css({
	'&:hover': {
		backgroundColor: token('color.background.accent.gray.subtlest', '#f1f2f4'),
	},
});

const selectedHoverAndFocusStyles = css({
	'&:hover, &:focus-within': {
		outline: `2px solid ${token('color.border.focused', B200)}`,
		outlineOffset: 0,
		borderRadius: token('border.radius', '3px'),
	},
});

const disabledStyle = css({
	cursor: 'not-allowed',
});
interface Props {
	isChecked: boolean;
	onClick: () => void;
	ToggleIcon: React.ElementType;
	disabled?: boolean;
	label: string;
}

export const PageCardAppearanceOption: FunctionComponent<Props> = ({
	isChecked,
	onClick,
	ToggleIcon,
	disabled = false,
	label,
}) => {
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const toggleFocus = useCallback(() => {
		setIsFocused(!isFocused);
	}, [isFocused]);

	return (
		<label
			css={[
				baseLabelStyle,
				disabled && disabledStyle,
				isChecked && !disabled ? selectedHoverAndFocusStyles : hoverAndFocusStyle,
			]}
		>
			<input
				type="radio"
				name="project-type-selection"
				css={inputStyle}
				checked={isChecked}
				onClick={onClick}
				onFocus={toggleFocus}
				onBlur={toggleFocus}
				aria-checked={isChecked}
				aria-label={label}
				disabled={disabled}
			/>

			{ToggleIcon && (
				<ToggleIcon
					size="medium"
					primaryColor={
						!disabled && isChecked
							? token('color.text.selected', N500)
							: token('color.text.subtlest', N70)
					}
				/>
			)}
		</label>
	);
};
