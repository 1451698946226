import type { FC } from 'react';
import React from 'react';

import { GridView, ListView, CompactListView, PopupView, EndOfPageView } from './Views';
import type { PageCardAppearance as PageCardAppearanceType } from './card-types';
import type { PageCardContextProviderProps } from './PageCardContextProvider';
import { PageCardContextProvider } from './PageCardContextProvider';

const getViewComponent = (appearance: PageCardAppearanceType) => {
	switch (appearance) {
		case 'grid':
			return GridView;
		case 'list':
			return ListView;
		case 'compact-list':
			return CompactListView;
		case 'popup-view':
			return PopupView;
		case 'end-of-page':
			return EndOfPageView;
	}
};

export const PageCard: FC<PageCardContextProviderProps> = (wrapperProps) => {
	const View = getViewComponent(wrapperProps.appearance);
	return (
		<PageCardContextProvider {...wrapperProps}>
			<View />
		</PageCardContextProvider>
	);
};
