import type { FC } from 'react';
import React, { useRef, useEffect } from 'react';

interface ViewportObserverProps {
	onViewportEnter: () => void;
	children?: React.ReactNode;
}

export const ViewportObserver: FC<ViewportObserverProps> = ({ onViewportEnter, children }) => {
	const observerRef = useRef<IntersectionObserver | null>(null);
	const targetRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		// eslint-disable-next-line
		observerRef.current = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting) {
				onViewportEnter();
			}
		});

		if (targetRef.current) {
			observerRef.current.observe(targetRef.current);
		}

		return () => {
			if (observerRef.current) {
				observerRef.current.disconnect();
				observerRef.current = null;
			}
		};
	}, [onViewportEnter]);

	return <div ref={targetRef}>{children}</div>;
};
